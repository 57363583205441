import * as React from "react";
import { FormattedMessage } from "react-intl";

const Arrow = ({ accordion, id }) => {
  if (accordion) {
    return (
      <svg
        width={40}
        height={40}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7502 7L20.7502 31.1793L31.4678 20.3774L32.5326 21.4339L20.5326 33.5282C20.3918 33.6702 20.2001 33.75 20.0002 33.75C19.8002 33.75 19.6086 33.6702 19.4678 33.5282L7.46777 21.4339L8.53258 20.3774L19.2502 31.1793L19.2502 7H20.7502Z"
          fill="currentColor"
        />
      </svg>
    );
  }

  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        <FormattedMessage id={id} />
      </title>
      <path
        d="M16 26.4082V28.6122H35.4286L25.9592 37.102L27.4694 39.0204L40 27.5102V27.4694L27.4694 16L25.9592 17.9184L35.4286 26.4082H16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Arrow;
