import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import * as React from "react";
import ButtonLink from "./button-link";

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },
  },

  renderText: (text) => {
    return text
      .split("\n")
      .flatMap((text, index) => [index > 0 && <br key={index} />, text]);
  },
};

const Banner = ({ heading, text, active, large, product, buttonLink }) => {
  const largeClass = large ? " banner-large" : "";
  const productClass = product ? " banner-product" : "";

  const titleFirstLine = large ? heading.split("\n")[0] : heading;
  const titleSecondLine = large ? heading.split("\n")[1] : null;

  if (!active) return null;

  return (
    <section
      className={`banner${largeClass}${productClass}`}
      aria-labelledby="banner-heading"
    >
      <div className="container">
        <div>
          {titleFirstLine ? (
            <h2 id="banner-heading" className="heading heading-large">
              <span>{titleFirstLine}</span>
              {titleSecondLine && titleSecondLine}
            </h2>
          ) : (
            <h2
              id="banner-heading"
              className={`heading${large ? " heading-large" : ""}`}
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          )}
          {text && renderRichText(text, renderOptions)}
          {buttonLink && (
            <ButtonLink
              href={buttonLink.link}
              button={buttonLink.button}
              backgroundColor={buttonLink.buttonBackgroundColour}
              textColor={buttonLink.textColour}
              target={buttonLink.target}
              className="banner-button"
            >
              {buttonLink.title}
            </ButtonLink>
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
