import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Link from "./link";
import ButtonLink from "./button-link";

SwiperCore.use([A11y]);

const Levels = ({ levels, title, intro, buttonLink }) => {
  const titleFirstLine = title?.levelsBlockTitle.split("\n")[0];
  const titleSecondLine = title?.levelsBlockTitle.split("\n")[1];

  return (
    <section className="levels" aria-labelledby="levels-heading">
      <div className="container">
        <header>
          <h2 id="levels-heading" className="heading heading-large">
            <span>{titleFirstLine}</span>
            {titleSecondLine && titleSecondLine}
          </h2>
          {intro && <p>{intro}</p>}
        </header>
        <Swiper className="levels-slideshow" slidesPerView="auto">
          {levels.map((level) => (
            <SwiperSlide
              key={level.contentful_id}
              style={{ "--color-level": level.color }}
            >
              <Link href="/levels/">
                <img
                  src={level.gauge.file.url}
                  alt={level.gauge.title}
                  width={level.gauge.file.details.image.width}
                  height={level.gauge.file.details.image.height}
                  loading="lazy"
                  decoding="async"
                />
                <h3>{level.title}</h3>
                {level.intro && <p>{level.intro.intro}</p>}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        {buttonLink && (
          <ButtonLink
            href={buttonLink.link}
            button={buttonLink.button}
            backgroundColor={buttonLink.buttonBackgroundColour}
            textColor={buttonLink.textColour}
            target={buttonLink.target}
            className="levels-button-link"
          >
            {buttonLink.title}
          </ButtonLink>
        )}
      </div>

      <StaticImage
        src="../assets/mountains-1.jpg"
        quality={80}
        alt="mountains"
      />
    </section>
  );
};

export default Levels;
