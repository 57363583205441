import * as React from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { FormattedMessage, useIntl } from "react-intl";
import SwiperCore, { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Link from "./link";
import Arrow from "./arrow";

SwiperCore.use([A11y, Navigation, Pagination]);

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },
};

const SlideImage = ({ image, mobileImage, imageAlt }) => {
  if (!image.gatsbyImageData) {
    return null;
  }

  const artDirected = [
    {
      media: "(max-width: 63.999em)",
      image: getImage(mobileImage),
    },
  ];

  const images = withArtDirection(
    getImage(image),
    mobileImage ? artDirected : []
  );

  return <GatsbyImage image={images} alt={imageAlt ?? image.title} />;
};

const Slideshow = ({
  slides,
  heading,
  title,
  fullWidth,
  backgroundColor,
  noPadding,
  product,
}) => {
  const { formatMessage } = useIntl();
  const fullWidthClass = fullWidth ? " slideshow-full-width" : "";
  const backgroundColorClass =
    backgroundColor === "Beige"
      ? " slideshow-light-yellow"
      : backgroundColor === "Blanc"
      ? " slideshow-white"
      : "";
  const noPaddingClass = noPadding ? " slideshow-no-padding" : "";
  const productClass = product ? " slideshow-product" : "";
  const headingText = formatMessage({ id: "slideshowHeading" });
  const prevEl = React.useRef(null);
  const nextEl = React.useRef(null);

  const titleFirstLine = title?.split("\n")[0];
  const titleSecondLine = title?.split("\n")[1];

  if (slides) {
    return (
      <section
        className={`slideshow${fullWidthClass}${backgroundColorClass}${noPaddingClass}${productClass}`}
        aria-labelledby={heading ? "slideshow-heading" : null}
      >
        <div className="container">
          {heading && titleFirstLine ? (
            <h2 id="slideshow-heading" className="heading heading-large">
              <span>{titleFirstLine}</span>
              {titleSecondLine && titleSecondLine}
            </h2>
          ) : heading ? (
            <h2
              id="slideshow-heading"
              className="heading heading-large"
              dangerouslySetInnerHTML={{ __html: headingText }}
            />
          ) : null}
          <div>
            <button ref={prevEl} className="swiper-button-prev">
              <Arrow id="prev" />
            </button>
            <button ref={nextEl} className="swiper-button-next">
              <Arrow id="next" />
            </button>
            <Swiper
              slidesPerView="auto"
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevEl.current;
                swiper.params.navigation.nextEl = nextEl.current;
              }}
              pagination={{ el: ".swiper-pagination", type: "fraction" }}
            >
              {slides.map((slide) => (
                <SwiperSlide key={slide.contentful_id}>
                  <div>
                    <p className="swiper-pagination" />
                    {slide.text && (
                      <div>{renderRichText(slide.text, renderOptions)}</div>
                    )}
                    {slide.link && (
                      <Link href={slide.link} className="link">
                        <FormattedMessage id="read" />
                      </Link>
                    )}
                  </div>
                  <div>
                    {slide.image && (
                      <SlideImage
                        image={slide.image}
                        mobileImage={slide.mobileImage}
                        imageAlt={slide.imageAlt}
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    );
  }

  return null;
};

export default Slideshow;
