import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { useIntl, FormattedMessage, FormattedNumber } from "react-intl";
import SwiperCore, { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import "swiper/css";
import Meta from "./meta";
import Close from "./close";
import Link from "./link";
import Arrow from "./arrow";
import { render } from "react-dom";
import ButtonLink from "./button-link";

SwiperCore.use([A11y, Navigation]);

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },
  },

  renderText: (text) => {
    return text
      .split("\n")
      .flatMap((text, index) => [index > 0 && <br key={index} />, text]);
  },
};

const query = graphql`
  query {
    destinations: allContentfulDestination(
      filter: { partner: { ne: true } }
      sort: { fields: order }
    ) {
      group(field: category___order) {
        fieldValue
        nodes {
          node_locale
          contentful_id
          title
          slug
          inProject
          category {
            title
          }
          dateRange
          products: product {
            contentful_id
            level {
              title
              slug
              order
            }
            price
          }
          intro {
            intro
          }
          featuredImage {
            description
            gatsbyImageData(quality: 80)
            title
          }
        }
      }
    }
  }
`;

const Destinations = ({ heading, intro, tabsOnDesktop, buttonLink }) => {
  const prevEl = React.useRef(null);
  const nextEl = React.useRef(null);
  const [tab, setTab] = React.useState(null);
  const { destinations } = useStaticQuery(query);
  const { locale } = useIntl();
  const sortProducts = (a, b) => a.level.order - b.level.order;
  const getLowestPrice = (products) => products.sort(sortProducts)[0].price;
  const isActiveLocale = ({ node_locale }) => node_locale === locale;
  const tabsOnDesktopClass = tabsOnDesktop
    ? " destinations-tabs-on-desktop"
    : "";

  return (
    <section
      className={`destinations${tabsOnDesktopClass}`}
      aria-labelledby="destinations-heading"
    >
      <div className="container">
        <header>
          <h2
            id="destinations-heading"
            className="heading heading-large"
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />
          {intro && intro.raw !== undefined ? (
            renderRichText(intro, renderOptions)
          ) : intro ? (
            <p>{intro}</p>
          ) : null}
        </header>
        <div className="destinations-slideshow">
          <button ref={prevEl} className="swiper-button-prev">
            <Arrow id="prev" />
          </button>
          <button ref={nextEl} className="swiper-button-next">
            <Arrow id="next" />
          </button>
          <Swiper
            slidesPerView="auto"
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 32,
              },
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevEl.current;
              swiper.params.navigation.nextEl = nextEl.current;
            }}
          >
            {destinations.group.map((group) => (
              <React.Fragment key={group.fieldValue}>
                {group.nodes.filter(isActiveLocale).map((destination) => (
                  <SwiperSlide key={destination.contentful_id}>
                    {!destination.inProject && (
                      <Link href={`/destinations/${destination.slug}/`}>
                        <span className="visually-hidden">
                          {destination.title}
                        </span>
                      </Link>
                    )}
                    <div>
                      <div className="swiper-slide-title">
                        <h3 aria-hidden>{destination.title}</h3>
                        <span className="link destinations-slideshow-booking-label">
                          <FormattedMessage id="book" />
                        </span>
                      </div>
                      {destination.featuredImage && (
                        <GatsbyImage
                          image={destination.featuredImage.gatsbyImageData}
                          alt={destination.featuredImage.title}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </React.Fragment>
            ))}
          </Swiper>
        </div>
        {buttonLink && (
          <ButtonLink
            button={buttonLink.button}
            backgroundColor={buttonLink.buttonBackgroundColour}
            textColor={buttonLink.textColour}
            href={buttonLink.link}
            target={buttonLink.target}
            className="destinations-button-link"
          >
            {buttonLink.title}
          </ButtonLink>
        )}
        {tabsOnDesktop && (
          <div className="destinations-tabs">
            <ul
              className="destinations-tabs-list"
              role="tablist" // eslint-disable-line
            >
              {destinations.group.map((group) => (
                <li role="presentation" key={group.fieldValue}>
                  <p>{group.nodes.find(isActiveLocale).category.title}</p>
                  <ul role="presentation">
                    {group.nodes
                      .filter(isActiveLocale)
                      .map(({ contentful_id: id, title }) => (
                        <li role="presentation" key={id}>
                          <button
                            role="tab"
                            aria-selected={tab === id ? true : false}
                            aria-controls={id}
                            onClick={() => setTab(id)}
                            tabIndex={tab === id ? 0 : tab !== null ? -1 : null}
                          >
                            {title}
                          </button>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>
            <div className="destinations-tabs-panels">
              <StaticImage src="../assets/map.jpg" alt="" quality={80} />
              <div className={`destinations-tabs-layer${tab ? " active" : ""}`}>
                <button onClick={() => setTab(null)}>
                  <Close />
                </button>
                {destinations.group.map((group) => (
                  <React.Fragment key={group.fieldValue}>
                    {group.nodes
                      .filter(isActiveLocale)
                      .map(({ contentful_id: id, ...destination }) => (
                        <div
                          id={id}
                          role="tabpanel"
                          aria-labelledby={`${destination.slug}-heading`}
                          hidden={tab !== id}
                          key={id}
                        >
                          {!destination.inProject && (
                            <Link href={`/destinations/${destination.slug}/`}>
                              <span className="visually-hidden">
                                {destination.title}
                              </span>
                            </Link>
                          )}
                          <div>
                            <div>
                              <h3
                                id={`${destination.slug}-heading`}
                                aria-hidden
                              >
                                {destination.title}
                              </h3>
                              <Meta destination={destination} />
                              {destination.intro && (
                                <p>{destination.intro.intro}</p>
                              )}
                            </div>
                            {!destination.inProject && destination.products && (
                              <span className="link">
                                <FormattedMessage id="from" />{" "}
                                <FormattedNumber
                                  value={getLowestPrice(destination.products)}
                                  style="currency" // eslint-disable-line
                                  currency="EUR"
                                  minimumFractionDigits={0}
                                />
                              </span>
                            )}
                          </div>
                          {destination.featuredImage && (
                            <GatsbyImage
                              image={destination.featuredImage.gatsbyImageData}
                              alt={destination.featuredImage.title}
                            />
                          )}
                        </div>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Destinations;
