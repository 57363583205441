import * as React from "react";
import { LiteYoutubeEmbed } from "react-lite-yt-embed";
import ButtonLink from "./button-link";

const getYoutubeId = (url) => {
  let id = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    id = url[2].split(/[^0-9a-z_-]/i);
    id = id[0];
  } else {
    id = url;
  }
  return id;
};

const Video = ({ video, intro, buttonLink }) => {
  if (!video) return null;

  const id = getYoutubeId(video.url);

  return (
    <div className="video">
      <div className="container">
        {intro && (
          <div className="video-intro">
            <h2 className="heading heading-large">{intro}</h2>
          </div>
        )}
        <figure>
          <LiteYoutubeEmbed id={id} noCookie mute={false} lazyImage />
        </figure>
        {buttonLink && (
          <ButtonLink
            button={buttonLink.button}
            backgroundColor={buttonLink.buttonBackgroundColour}
            textColor={buttonLink.textColour}
            href={buttonLink.link}
            target={buttonLink.target}
          >
            {buttonLink.title}
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

export default Video;
