import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Destinations from "../components/destinations";
import Levels from "../components/levels";
import Video from "../components/video";
import Banner from "../components/banner";
import Slideshow from "../components/slideshow";
import ImageWithText from "../components/image-with-text";
import HomeHero from "../components/home-hero";

const IndexPage = ({ data }) => {
  const { home } = data;

  return (
    <Layout whiteHeader>
      <Seo home />
      <HomeHero
        title={home.title}
        subtitle={home.subtitle}
        intro={home.intro?.intro}
        heroMedia={home.heroMedia}
      />
      <Banner
        heading={home.banner?.heading}
        text={home.banner?.text}
        link={home.banner?.link}
        linkText={home.banner?.linkText}
        active={home.banner?.active}
        buttonLink={home.banner?.buttonLink}
      />
      <Destinations
        heading={home.destinationsBlocTitle}
        intro={home.destinationsBlocText}
        buttonLink={home.destinationsBlocButton}
      />
      <Levels
        levels={home.levelsBlocContent}
        title={home.levelsBlockTitle}
        intro={home.levelsBlockText?.levelsBlockText}
        buttonLink={home.levelsBlockButton}
      />
      {home.video?.isVideoActive && (
        <Video
          video={home.video}
          intro={home.videoBlockTitle}
          buttonLink={home.videoBlockButton}
        />
      )}
      <Banner
        heading={home.blogBlockTitle.blogBlockTitle}
        buttonLink={home.blogBlockButton}
        active
        large
      />
      <Slideshow
        slides={home.quotations}
        title={home.quotationsTitle?.quotationsTitle}
        heading
      />
      <ImageWithText
        image={home.seoSection?.image}
        mobileImage={home.seoSection?.mobileImage}
        imageAlt={home.seoSection?.imageAlt}
        text={home.seoSection?.text}
      />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($locale: String!) {
    home: contentfulHome(
      node_locale: { eq: $locale }
      contentful_id: { eq: "62rvf78k5yrgR3aq2foZ58" }
    ) {
      title
      subtitle
      intro {
        intro
      }
      banner {
        heading
        text {
          raw
        }
        link
        linkText
        active
        buttonLink {
          button
          buttonBackgroundColour
          textColour
          title
          link
          target
        }
      }
      heroMedia {
        title
        file {
          url
          contentType
        }
        description
        gatsbyImageData(quality: 80)
      }
      levelsBlocContent {
        color
        contentful_id
        intro {
          intro
        }
        gauge {
          title
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        order
        title
      }
      video {
        intro {
          raw
        }
        url
        isVideoActive
      }
      quotationsTitle {
        quotationsTitle
      }
      quotations {
        contentful_id
        image {
          description
          title
          gatsbyImageData(quality: 80)
        }
        mobileImage {
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
        link
      }
      seoSection {
        image {
          description
          title
          gatsbyImageData(quality: 80)
        }
        mobileImage {
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
      }
      blogBlockButton {
        button
        buttonBackgroundColour
        link
        target
        textColour
        title
      }
      blogBlockTitle {
        blogBlockTitle
      }
      destinationsBlocButton {
        button
        buttonBackgroundColour
        link
        target
        textColour
        title
      }
      destinationsBlocText {
        raw
      }
      destinationsBlocTitle
      levelsBlockButton {
        button
        buttonBackgroundColour
        link
        target
        textColour
        title
      }
      levelsBlockText {
        levelsBlockText
      }
      levelsBlockTitle {
        levelsBlockTitle
      }
      videoBlockButton {
        button
        buttonBackgroundColour
        link
        target
        textColour
        title
      }
      videoBlockTitle
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
