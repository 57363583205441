import * as React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import Link from "./link";
import ButtonLink from "./button-link";

const query = graphql`
  query {
    allContentfulLink(
      filter: {
        metadata: {
          tags: { elemMatch: { name: { eq: "destinationsCompareTopBar" } } }
        }
      }
    ) {
      nodes {
        node_locale
        id
        button
        buttonBackgroundColour
        target
        textColour
        title
        link
      }
    }
  }
`;

const Meta = ({ destination, compare, bar }) => {
  const { allContentfulLink } = useStaticQuery(query);
  const { locale } = useIntl();
  const products = destination?.products;
  const sortProducts = (a, b) => a.level.order - b.level.order;
  const sortedProducts = products?.sort(sortProducts) ?? [];
  const destinationPath = `/destinations/${destination?.slug}/`;
  const productPath = `${destinationPath}${sortedProducts?.[0]?.level?.slug}/`;
  const ctaPath = bar ? productPath : destinationPath;

  const compareButton = allContentfulLink.nodes.find(
    (item) => item.node_locale === locale
  );

  return (
    <div className={`meta${bar ? " meta-bar" : ""}`}>
      <p className="meta-date">{destination.dateRange}</p>
      {sortedProducts.length > 1 && (
        <div className="meta-levels">
          <strong>
            <FormattedMessage id="metaLevels" />
          </strong>
          <ul // eslint-disable-line
            role="list"
          >
            {sortedProducts.map((product) => (
              <li key={product.contentful_id}>
                {!destination.inProject ? (
                  <Link
                    href={`/destinations/${destination.slug}/${product.level?.slug}/`}
                  >
                    {product.level?.title}
                  </Link>
                ) : (
                  product.level?.title
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!destination.inProject && (
        <div className="meta-actions">
          {compareButton && (
            <ButtonLink
              href={compareButton.link}
              backgroundColor={compareButton.buttonBackgroundColour}
              textColor={compareButton.textColour}
              button={compareButton.button}
              target={compareButton.target}
            >
              {compareButton.title}
            </ButtonLink>
          )}
          <Link href={ctaPath} className="link">
            <FormattedMessage id="from" />{" "}
            <FormattedNumber
              value={sortedProducts[0]?.price}
              style="currency" // eslint-disable-line
              currency="EUR"
              minimumFractionDigits={0}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Meta;
